vue
<template>
    <div>
        <div class="p-grid">
            <div class="p-col-12 p-d-flex p-jc-center">
                <p class="title-bold">Peeling</p>
            </div>
        </div>

        <div class="p-grid">
            <div class="p-col-12 p-d-flex p-jc-center">
                <img class="img-fluid" alt="prxt33" src="../../assets/medicinaEsteticaPRXT33.jpg" />
            </div>

            <div class="p-col-10 p-offset-1 p-d-flex p-jc-center">
                <Card styleClass="p-card-shadow">
                    <template #content>
                        <p>
                            El <span class="p-text-bold">PRX-T33</span> es un revolucionario tratamiento de peeling que
                            ofrece resultados sorprendentes para mejorar la apariencia de la piel. Esta innovadora fórmula,
                            compuesta por <span class="p-text-bold">TCA al 33%</span>, <span class="p-text-bold">agua
                                oxigenada</span> y <span class="p-text-bold">ácido kójico al 5%</span>, estimula los
                            fibroblastos sin dañar la piel ni causar inflamación.
                        </p>
                        <p>
                            Una de las principales ventajas del <span class="p-text-bold">PRX-T33</span> es su versatilidad,
                            ya que se puede realizar en cualquier momento del año y en todo tipo de piel. Además, no es
                            fotosensible, lo que significa que no se verá afectado por la exposición al sol.
                        </p>
                        <p>
                            Al aplicar el <span class="p-text-bold">PRX-T33</span>, la piel recibe una poderosa dosis de
                            luminosidad y brillo, logrando una <span class="p-text-bold">biorevitalización</span> profunda y
                            mejorando notablemente la flacidez del tejido. Los fibroblastos se activan, estimulando la
                            producción de colágeno y elastina, lo que resulta en una piel más firme y rejuvenecida.
                        </p>
                        <p>
                            Otro beneficio destacado del <span class="p-text-bold">PRX-T33</span> es su capacidad para
                            complementarse con otros tratamientos, como la <span class="p-text-bold">mesoterapia capilar</span>, potenciando así los resultados. Además, la <span
                                class="p-text-bold">fórmula magistral</span> recetada por nuestros expertos ayuda a lograr
                            un mayor blanqueamiento de la piel y reducción de manchas o melasmas.
                        </p>
                        <p>
                            En resumen, el <span class="p-text-bold">PRX-T33</span> es una solución revolucionaria para
                            rejuvenecer la piel sin cirugía. Sus beneficios incluyen la estimulación de los fibroblastos, la
                            mejora de la flacidez, la luminosidad y el brillo en la piel, y la capacidad de complementarse
                            con otros tratamientos. ¡No pierdas la oportunidad de experimentar una piel más radiante y
                            rejuvenecida con el <span class="p-text-bold">PRX-T33</span>!
                        </p>
                       

                    </template>

                    <template #footer>
                        <span>Para más información no dude en ponerse en contacto con nuestro
                            Centro médico al 637 857 384.</span>
                    </template>
                </Card>
            </div>
        </div>
    </div>
</template>

<script>
import Card from 'primevue/card';
export default {
    name: 'PeelingVue',
    data() {
        return {
            // Propiedades de datos del componente
        };
    },
    components: {
        Card
    },
    methods: {
        // Métodos del componente
    },
    computed: {
        // Propiedades computadas del componente
    },
    created() {
        // Lógica que se ejecuta cuando el componente se crea
    },
    mounted() {
        // Lógica que se ejecuta cuando el componente se monta en el DOM
    },
    destroyed() {
        // Lógica que se ejecuta cuando el componente se destruye
    },
};
</script>

<style scoped>.title-bold {
    font-size: 30px;
    font-weight: bold
}

.img-fluid {
    width: 100%;
    height: auto;
}

@media (min-width: 576px) {
    .img-fluid {
        max-width: 50%;
        height: auto;
    }
}</style>